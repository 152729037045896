import React from 'react';
import { Box, Typography, Paper, TextField, Button } from '@mui/material';
import FrontendInfoBar from '../components/FrontendInfoBar';
import FrontendScrollingBanner from '../components/FrontendScrollingBanner';
import sampleTable from '../graphics/SampleTable.png';
import FrontendEmailForm from '../components/FrontendEmailForm';
import { ReactComponent as HomeMap } from '../graphics/HomeMap.svg';
import TargetBlackIcon from '../icons/TargetBlack.png';
import CampaignIcon from '../icons/Campaign.png';
import ArrowIcon from '../icons/Arrow 4.png';
import MoneyBagIcon from '../icons/moneybag.png';
import BottomBar from '../components/BottomBar';

const FrontendHome = () => {
  const sectionHeight = 'calc(70vh)';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflowX: 'hidden', backgroundColor: '#FFFFFF' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" paddingTop={{ xs: '0px', md: '100px' }} textAlign="center" width="100%">
        <Paper elevation={0} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '40px', marginBottom: { xs: '20px', md: '40px' } }}>
          <Typography variant="h2" sx={{ fontSize: { xs: '10vw', sm: '7vw', md: '6vw', lg: '6.5vw' }, fontWeight: 600, color: '#000', fontFamily: 'SF Pro Display, sans-serif' }}>
            Reach The World
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: '8vw', sm: '5.5vw', md: '5vw', lg: '4.5vw' }, fontWeight: 600, color: '#000', fontFamily: 'SF Pro Display, sans-serif' }}>
            with <span style={{ color: '#01AC1C' }}>LeadHunter</span>
          </Typography>
        </Paper>
        <Box display="flex" justifyContent="center" width="100%" marginTop={{ xs: '20px', md: '40px' }}>
          <HomeMap style={{ height: 'auto', minHeight: '350px', width: '92.5%', }} />
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center" width="100%" marginTop={{ xs: '10px', md: '60px' }} padding={{ xs: '10px 0', md: '40px 0' }} gap={{ xs: '20px', md: '60px' }}>
          <Box display="flex" alignItems="center">
            <img src={TargetBlackIcon} alt="Target Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            <Typography variant="h5" sx={{ fontSize: { xs: '4.5vw', md: '1.5vw' }, fontWeight: 600, color: '#000' }}>
              Target Ideal <span style={{ color: '#01AC1C' }}>Customers</span>
            </Typography>
          </Box>
          <img src={ArrowIcon} alt="Arrow Icon" style={{ width: '40px', height: '20px' }} />
          <Box display="flex" alignItems="center">
            <img src={CampaignIcon} alt="Campaign Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            <Typography variant="h5" sx={{ fontSize: { xs: '4.5vw', md: '1.5vw' }, fontWeight: 600, color: '#000' }}>
              Outreach at <span style={{ color: '#01AC1C' }}>Scale</span>
            </Typography>
          </Box>
          <img src={ArrowIcon} alt="Arrow Icon" style={{ width: '40px', height: '20px' }} />
          <Box display="flex" alignItems="center">
            <img src={MoneyBagIcon} alt="Money Bag Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
            <Typography variant="h5" sx={{ fontSize: { xs: '4.5vw', md: '1.5vw' }, fontWeight: 600, color: '#000' }}>
              Close New <span style={{ color: '#01AC1C' }}>Business</span>
            </Typography>
          </Box>
        </Box>
        <FrontendInfoBar />
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" width="100%" height={sectionHeight} sx={{ backgroundColor: '#F2F2F2' }}>
          <Box flexBasis={{ xs: '100%', md: '50%' }} paddingLeft={{ md: '80px' }} display="flex" flexDirection="column" justifyContent="flex-start" alignItems={{ xs: 'center', md: 'flex-start' }} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography variant="h3" sx={{ fontSize: { xs: '8vw', sm: '6vw', md: '44px', lg: '44px' }, fontWeight: 600, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginBottom: '20px' }}>
              Find Ideal <span style={{ color: '#01AC1C' }}>Leads</span>
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px' }}>
              Save time by focusing on leads that match your specific requirements
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px' }}>
              Filter by zip code, home value, lot size, bedrooms, bathrooms, age, and more
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px' }}>
              No more sifting through irrelevant contacts
            </Typography>
          </Box>
          <Box flexBasis={{ xs: '100%', md: '50%' }} padding={{ xs: '0 25px', md: '0 80px' }} display="flex" justifyContent="center" alignItems="center">
            <img src={sampleTable} alt="Sample Table" style={{ width: '114%', height: 'auto', borderRadius: '30px' }} />
          </Box>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center" width="100%" height={sectionHeight} sx={{ backgroundColor: '#FFFFFF' }}>
          <Box flexBasis={{ xs: '100%', md: '50%' }} paddingLeft={{ md: '80px' }} display="flex" flexDirection="column" justifyContent="center" alignItems={{ xs: 'center', md: 'flex-start' }} textAlign={{ xs: 'center', md: 'left' }}>
            <FrontendEmailForm />
          </Box>
          <Box flexBasis={{ xs: '100%', md: '50%' }} paddingRight={{ md: '80px' }} display="flex" flexDirection="column" justifyContent="flex-start" alignItems={{ xs: 'center', md: 'flex-start' }} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography variant="h3" sx={{ fontSize: { xs: '8vw', sm: '6vw', md: '44px', lg: '44px' }, fontWeight: 600, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginBottom: '20px' }}>
              Tailored Email <span style={{ color: '#01AC1C' }}>Campaigns</span>
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px', textAlign: 'left' }}>
              Craft customized email messages that resonate with your target audience
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px', textAlign: 'left' }}>
              We handle the heavy lifting, sending your campaign out at scale
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '4.5vw', md: '24px' }, fontWeight: 500, color: '#000', fontFamily: 'SF Pro Display, sans-serif', marginTop: '20px', textAlign: 'left' }}>
              Spend time closing deals, not sending emails
            </Typography>
          </Box>
        </Box>
        <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: '400', textAlign: 'center', marginTop: { xs: '380px', md: '90px' }, marginBottom: '50px' }}>
          Trusted by local businesses
        </Typography>
        <FrontendScrollingBanner />
        <Box width="100%" textAlign="center" marginTop="20px" paddingBottom="60px" id="signup">
          <Box sx={{ borderRadius: '30px', padding: '40px', marginTop: '80px', height: { xs: 'auto', md: '200px' }, width: { xs: 'auto', md: 'auto' }, minWidth: { xs: '300px', md: 'auto' }, marginX: 'auto' }}>
            <Typography variant="h4" sx={{ fontSize: { xs: '40px', md: '40px' }, fontWeight: '500', color: '#000', marginBottom: '20px', fontFamily: 'SF Pro Display, sans-serif' }}>
              See how LeadHunter can get you more customers
            </Typography>
            <Box display="flex" justifyContent="center">
              <TextField variant="outlined" placeholder="Enter your email" sx={{ marginRight: '10px', width: '300px' }} />
              <Button variant="contained" style={{ backgroundColor: '#01AC1C' }}>Submit</Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <BottomBar />
    </div>
  );
};

export default FrontendHome;
