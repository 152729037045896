import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import logo from '../icons/Logo.svg';
import facebookLogo from '../graphics/facebook.png';
import instagramLogo from '../graphics/instagram.png';
import linkedinLogo from '../graphics/linkedin.png';
import tiktokLogo from '../graphics/tiktok.svg';
import twitterLogo from '../graphics/twitter.png';
import youtubeLogo from '../graphics/youtube.png';

const TopBar = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/home');
  };

  const handleContactClick = () => {
    setShowContactPopup(true);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  const handleClose = () => {
    setShowContactPopup(false);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#ffffff', boxShadow: '0px 1px 1px #DFE5F1', borderBottom: '1px solid #DFE5F1', padding: '10px 0' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center">
          <img src={logo} alt="Logo" style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }} onClick={handleHomeClick} />
          <Typography variant="h6" component="div" onClick={handleHomeClick} sx={{ cursor: 'pointer', fontFamily: 'SF Pro Display, sans-serif', fontWeight: 700, color: '#333', fontSize: '18px' }}>
            Leadhunter
          </Typography>
        </Box>
        <Box>
          <IconButton color="inherit" onClick={handleHomeClick} sx={{ color: '#333' }}>
            <HomeIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleContactClick} sx={{ color: '#333' }}>
            <PersonIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleSettingsClick} sx={{ color: '#333' }}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </Toolbar>

      <Dialog open={showContactPopup} onClose={handleClose}>
        <DialogTitle>Contact</DialogTitle>
        <DialogContent>
          <List>
            <ListItem button component="a" href="https://www.instagram.com/natecreates" target="_blank">
              <ListItemIcon>
                <img src={instagramLogo} alt="Instagram" style={{ height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Instagram" />
            </ListItem>
            <ListItem button component="a" href="https://www.tiktok.com/@natecreateshq" target="_blank">
              <ListItemIcon>
                <img src={tiktokLogo} alt="TikTok" style={{ height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="TikTok" />
            </ListItem>
            <ListItem button component="a" href="https://youtube.com/@natecreateshq?si=uej_C_SIrghoHBJK" target="_blank">
              <ListItemIcon>
                <img src={youtubeLogo} alt="YouTube" style={{ height: '24px', maxWidth: '24px', objectFit: 'contain' }} />
              </ListItemIcon>
              <ListItemText primary="YouTube" />
            </ListItem>
            <ListItem button component="a" href="https://www.facebook.com/natecreateshq" target="_blank">
              <ListItemIcon>
                <img src={facebookLogo} alt="Facebook" style={{ height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Facebook" />
            </ListItem>
            <ListItem button component="a" href="https://www.linkedin.com/in/nathaniel-laquis" target="_blank">
              <ListItemIcon>
                <img src={linkedinLogo} alt="LinkedIn" style={{ height: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="LinkedIn" />
            </ListItem>
            <ListItem button component="a" href="https://www.twitter.com/natecreateshq" target="_blank">
              <ListItemIcon>
                <img src={twitterLogo} alt="Twitter" style={{ height: '24px', borderRadius: '12%' }} />
              </ListItemIcon>
              <ListItemText primary="Twitter" />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

export default TopBar;
