import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import FrontendHome from './pages/FrontendHome';
import FrontendContact from './pages/FrontendContact';
import FrontendAbout from './pages/FrontendAbout';
import PlatformPage from './pages/PlatformPage';
import Settings from './pages/Settings';
import TopBar from './components/TopBar';
import FrontendTopBar from './components/FrontendTopBar';
import PersonSearch from './pages/PersonSearch';
import PersonLookup from './pages/PersonLookup';
import PersonDetails from './pages/PersonDetails';
import './App.css';
import theme from './themes/theme';
import { auth } from './firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

function App() {
  const [user] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {user ? <TopBar /> : <FrontendTopBar />}
        <Routes>
          <Route path="/" element={user ? <Navigate to="/home" /> : <FrontendHome />} />
          <Route path="/signup" element={user ? <Navigate to="/home" /> : <SignUp />} />
          <Route path="/login" element={user ? <Navigate to="/home" /> : <Login />} />
          <Route path="/home" element={user ? <Home /> : <FrontendHome />} />
          <Route path="/contact" element={<FrontendContact />} />
          <Route path="/about" element={<FrontendAbout />} />
          <Route path="/settings" element={user ? <Settings /> : <Navigate to="/" />} />
          <Route path="/platform/:platform" element={<PlatformPage />} /> {/* Dynamic platform route */}
          <Route path="/person-search" element={<PersonSearch />} />
          <Route path="/person-lookup" element={<PersonLookup />} />
          <Route path="/person-details" element={<PersonDetails />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
