import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../icons/Logo.svg';

const FrontendTopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);

  const handleHomeClick = () => {
    setDrawerOpen(false);
    navigate('/');
  };

  // Commented out About and Contact click handlers
  // const handleAboutClick = () => {
  //   setDrawerOpen(false);
  //   navigate('/frontend-about');
  // };

  // const handleContactClick = () => {
  //   setDrawerOpen(false);
  //   navigate('/frontend-contact');
  // };

  const handleLoginClick = () => {
    setDrawerOpen(false);
    navigate('/login');
  };

  const handleSignUpClick = () => {
    setDrawerOpen(false);
    navigate('/signup');
  };

  const getLinkStyle = (path) => ({
    color: location.pathname === path ? '#01AC1C' : '#A5B4CB',
    fontSize: '18px',
    fontFamily: 'SF Pro Display, sans-serif',
    padding: '0 18px',
    textDecoration: location.pathname === path ? 'underline' : 'none',
    cursor: 'pointer',
    fontWeight: 'normal',
  });

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Close the drawer if the window is resized to a larger viewport
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 960 && drawerOpen) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawerOpen]);

  // Close the drawer if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    if (drawerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawerOpen]);

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: '#ffffff',
        boxShadow: 'inset 0 -1px 0 0 #DFE5F1',
        height: '76px',
      }}
    >
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
        <Box display="flex" alignItems="center">
          <img
            src={logo}
            alt="Logo"
            className="logo"
            onClick={handleHomeClick}
            style={{ height: '30px', marginRight: '10px', cursor: 'pointer' }}
          />
          <Typography
            variant="h6"
            component="div"
            onClick={handleHomeClick}
            style={{
              cursor: 'pointer',
              fontFamily: 'SF Pro Display, sans-serif',
              fontWeight: 700,
              fontSize: '18px',
              color: '#333',
            }}
          >
            LeadHunter
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center">
          <Typography onClick={handleHomeClick} style={getLinkStyle('/')}>
            Home
          </Typography>
          {/* Commented out About and Contact links */}
          {/* <Typography onClick={handleAboutClick} style={getLinkStyle('/frontend-about')}>
            About
          </Typography>
          <Typography onClick={handleContactClick} style={getLinkStyle('/frontend-contact')}>
            Contact
          </Typography> */}
          <Button
            onClick={handleLoginClick}
            style={{
              ...getLinkStyle('/login'),
              border: '1px solid rgba(140, 140, 140, 0.35)',
              borderRadius: '30px',
              marginRight: '18px',
              height: '36px',
              textTransform: 'none', // Ensure text is not all caps
            }}
          >
            Login
          </Button>
          <Button
            onClick={handleSignUpClick}
            style={{
              backgroundColor: '#01AC1C',
              color: '#fff',
              fontSize: '18px',
              fontFamily: 'SF Pro Display, sans-serif',
              padding: '0 18px',
              textTransform: 'none', // Ensure text is not all caps
              borderRadius: '30px',
              height: '40px',
            }}
          >
            Sign Up
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon style={{ color: '#333' }} />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        ref={drawerRef}
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '250px',
          height: '100%',
          backgroundColor: '#fff',
          boxShadow: 'inset 0 -1px 0 0 #DFE5F1',
          zIndex: 1300,
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          transition: 'transform 0.3s ease-in-out',
          transform: drawerOpen ? 'translateX(0)' : 'translateX(100%)',
        }}
      >
        <Typography onClick={handleHomeClick} style={{ ...getLinkStyle('/'), padding: '10px 0' }}>
          Home
        </Typography>
        {/* Commented out About and Contact links in the drawer */}
        {/* <Typography onClick={handleAboutClick} style={{ ...getLinkStyle('/frontend-about'), padding: '10px 0' }}>
          About
        </Typography>
        <Typography onClick={handleContactClick} style={{ ...getLinkStyle('/frontend-contact'), padding: '10px 0' }}>
          Contact
        </Typography> */}
      </Box>
    </AppBar>
  );
};

export default FrontendTopBar;
