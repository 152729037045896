import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config';

const Settings = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" style={{ backgroundColor: '#ffffff' }}>
      <Typography variant="h4" gutterBottom style={{ color: '#333' }}>
        Settings
      </Typography>
      <Button variant="contained" style={{ backgroundColor: '#01AC1C', color: '#ffffff' }} onClick={handleLogout}>
        Logout
      </Button>
    </Box>
  );
};

export default Settings;
