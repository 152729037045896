import React from 'react';
import { Box, Typography, Paper, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';

const PersonDetails = () => {
  const location = useLocation();
  const personData = location.state?.personData || {}; // Get data from location state

  const generateGoogleMapsLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  // Function to handle sidebar clicks and smooth scroll to the section
  const handleSidebarClick = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to format dates
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'N/A' : date.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });
  };

  return (
    <Box display="flex" sx={{ padding: '20px', backgroundColor: '#ffffff' }}>
      {/* Left Navigation Sidebar */}
      <Box sx={{ minWidth: '240px', maxWidth: '300px', padding: '20px', backgroundColor: '#ffffff', borderRight: '1px solid #ddd', display: { xs: 'none', md: 'block' } }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          {personData.name || "N/A"} Profile
        </Typography>
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="body2" component="a" href="#current-address" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'current-address')}>Current Address</Typography>
          <Typography variant="body2" component="a" href="#phone-number" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'phone-number')}>Phone Number</Typography>
          <Typography variant="body2" component="a" href="#email" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'email')}>Email</Typography>
          <Typography variant="body2" component="a" href="#addresses-history" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'addresses-history')}>Addresses History</Typography>
          <Typography variant="body2" component="a" href="#relatives" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'relatives')}>Relatives</Typography>
          <Typography variant="body2" component="a" href="#associates" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'associates')}>Associates</Typography>
          <Typography variant="body2" component="a" href="#akas" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'akas')}>Aliases</Typography>
          <Typography variant="body2" component="a" href="#marital-status" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'marital-status')}>Marital Status</Typography>
          <Typography variant="body2" component="a" href="#education" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'education')}>Education</Typography>
          <Typography variant="body2" component="a" href="#alive-status" color="textPrimary" onClick={(e) => handleSidebarClick(e, 'alive-status')}>Alive Status</Typography>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {/* Name Section */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
          {personData.name || "Name not available"}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Age {personData.age || "N/A"}
        </Typography>

        {/* First Section: Summary */}
        {personData.propertyDetails && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Current Address <span style={{ fontWeight: 'normal' }}>(Since {formatDate(personData.propertyDetails["Last Sale Date"])})</span>
            </Typography>
            {personData.address && (
              <>
                <Link href={generateGoogleMapsLink(personData.address)} target="_blank" rel="noopener noreferrer" variant="body1" sx={{ color: '#01AC1C', textDecoration: 'none', display: 'block', marginLeft: '20px' }}>
                  {personData.address}
                </Link>
                <Typography variant="body2" color="textSecondary" sx={{ marginLeft: '20px', marginTop: '5px' }}>
                  County not available
                </Typography>
              </>
            )}

            {/* Summary Property Details */}
            <Box sx={{ marginTop: '20px', marginLeft: '20px' }}>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                {personData.propertyDetails.Bedrooms || "N/A"} Beds | {personData.propertyDetails.Bathrooms || "N/A"} Bath | {personData.propertyDetails['Square Feet'] || "N/A"} Sq.Ft. | Built in {personData.propertyDetails['Year Built'] || "N/A"}
              </Typography>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Box>
                  <Typography variant="body2" color="textSecondary">Estimated Value</Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>{personData.propertyDetails["Estimated Value"] || "N/A"}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Estimated Equity</Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>{personData.propertyDetails["Estimated Equity"] || "N/A"}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Last Sale Amount</Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>{personData.propertyDetails["Last Sale Amount"] || "N/A"}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">Last Sale Date</Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>{formatDate(personData.propertyDetails["Last Sale Date"])}</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        )}

        {/* Embedded Google Map */}
        {personData.address && (
          <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <iframe
              title={`Map of ${personData.address}`}
              width="100%"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(personData.address)}&key=AIzaSyCRJPiQjB3i9JbPX7GieaEq9aLzwfQPQ9g`}
              allowFullScreen
            ></iframe>
          </Box>
        )}

        {/* Second Section: Detailed Property Information */}
        {personData.propertyDetails && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="current-address">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Current Address Property Details (Since {formatDate(personData.propertyDetails["Last Sale Date"])})
            </Typography>
            {personData.address && (
              <Link href={generateGoogleMapsLink(personData.address)} target="_blank" rel="noopener noreferrer" variant="body1" sx={{ color: '#01AC1C', textDecoration: 'none' }}>
                {personData.address}
              </Link>
            )}
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '8px' }}>
              County not available
            </Typography>

            {/* Detailed Property Details */}
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', columnGap: '20px', marginTop: '20px' }}>
              {Object.keys(personData.propertyDetails).map((key, index) => (
                <Box key={index} sx={{ marginBottom: '10px' }}>
                  <Typography variant="body2" color="textSecondary">{key.replace(/([A-Z])/g, ' $1').trim()}</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {personData.propertyDetails[key] || "N/A"}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Paper>
        )}

        {/* Phone Numbers Section */}
        {(personData.primaryPhone?.primary?.number || personData.otherPhones?.length > 0) && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="phone-number">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Phone Numbers:
            </Typography>
            {personData.primaryPhone?.primary?.number && (
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {personData.primaryPhone.primary.number} <span style={{ color: '#FF4500' }}>(Primary)</span>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {personData.primaryPhone.primary.type || "N/A"}, {personData.primaryPhone.primary.provider || "N/A"} <br />
                  {personData.primaryPhone.primary.firstReported || "N/A"}
                </Typography>
              </Box>
            )}
            {personData.otherPhones?.map((phone, index) => (
              <Box key={index} sx={{ marginBottom: '20px' }}>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {phone.number || "N/A"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {phone.type || "N/A"}, {phone.provider || "N/A"} <br />
                  {phone.firstReported || "N/A"}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}

        {/* Email Section */}
        {(personData.email || personData.other_emails?.length > 0) && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="email">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Emails:
            </Typography>
            {personData.email && (
              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {personData.email || "N/A"} <span style={{ color: '#FF4500' }}>(Primary)</span>
                </Typography>
              </Box>
            )}
            {personData.other_emails?.map((email, index) => (
              <Box key={index} sx={{ marginBottom: '20px' }}>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {email || "N/A"}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}

        {/* Previous Addresses Section */}
        {personData.previousAddresses?.length > 0 && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="addresses-history">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Previous Addresses:
            </Typography>
            {personData.previousAddresses.map((address, index) => (
              <Link key={index} href={generateGoogleMapsLink(address)} target="_blank" rel="noopener noreferrer" variant="body1" sx={{ color: '#01AC1C', textDecoration: 'none' }} display="block" gutterBottom>
                {address || "N/A"}
              </Link>
            ))}
          </Paper>
        )}

        {/* Relatives Section */}
        {personData.relatives?.length > 0 && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="relatives">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Relatives:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '60px' }}>
              {personData.relatives.map((relative, index) => (
                <Typography key={index} variant="body1" color="textPrimary">
                  {relative || "N/A"}
                </Typography>
              ))}
            </Box>
          </Paper>
        )}

        {/* Associates Section */}
        {personData.associates?.length > 0 && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="associates">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Associates:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '60px' }}>
              {personData.associates.map((associate, index) => (
                <Typography key={index} variant="body1" color="textPrimary">
                  {associate || "N/A"}
                </Typography>
              ))}
            </Box>
          </Paper>
        )}

        {/* Aliases Section */}
        {personData.akas?.length > 0 && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="akas">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Aliases:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '60px' }}>
              {personData.akas.map((alias, index) => (
                <Typography key={index} variant="body1" color="textPrimary">
                  {alias || "N/A"}
                </Typography>
              ))}
            </Box>
          </Paper>
        )}

        {/* Marital Status Section */}
        {personData.maritalStatus && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="marital-status">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Marital Status:
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {personData.maritalStatus || "N/A"}
            </Typography>
          </Paper>
        )}

        {/* Education Section */}
        {personData.education?.length > 0 && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="education">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Education for {personData.name || "N/A"}
            </Typography>
            {personData.education.map((edu, index) => (
              <Box key={index}>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {edu.school || "N/A"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {edu.degree || "N/A"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {edu.fieldOfStudy || "N/A"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {edu.years || "N/A"}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}

        {/* Alive Status Section */}
        {personData.aliveStatus && (
          <Paper elevation={0} sx={{ padding: '20px', marginBottom: '20px', backgroundColor: '#ffffff', boxShadow: 'inset 0px -1px 0px #DFE5F1', borderRadius: '0px' }} id="alive-status">
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
              Alive Status:
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {personData.aliveStatus || "N/A"}
            </Typography>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default PersonDetails;
