import React, { useState } from 'react';
import { Box, Typography, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Map } from '../graphics/Map.svg';
import BottomBar from '../components/BottomBar';
import axios from 'axios';

const PersonLookup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    city: '',
    state: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = 'https://leadhunter-backend.onrender.com/api/search';  // Replace with your actual Render URL
      const response = await axios.post(url, formData);
      setLoading(false);
      navigate('/person-search', { state: { results: response.data } });  // Pass results via navigate
    } catch (error) {
      console.error('Error fetching search results:', error.message);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop="150px" textAlign="center" paddingX={{ xs: '10px', md: '0' }}>
        <Paper elevation={0} style={{ backgroundColor: 'rgba(255, 255, 255, 0)', padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h2" sx={{ fontSize: { xs: '10vw', md: '87px' }, fontWeight: 600, color: '#000' }}>
            With Power Comes
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: '8vw', md: '64px' }, fontWeight: 600, color: '#000' }}>
            Great Responsibility
          </Typography>
        </Paper>
        <Box position="relative" width="100%" display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
          <Map style={{ width: '100%', height: 'auto' }} />
          <Box position="absolute" width={{ xs: '90%', md: '80%' }} top={{ xs: '10%', md: '15%' }}>
            <Paper style={{ padding: '40px', borderRadius: '45px', backgroundColor: '#fff', textAlign: 'left', boxShadow: 'none' }}>
              <form noValidate autoComplete="off" onSubmit={handleSearch}>
                <Typography variant="h6" sx={{ marginBottom: '20px', color: '#000', fontSize: { xs: '18px', md: '23px' }, fontWeight: '500' }}>Full Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  sx={{ marginBottom: '20px' }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Middle Name (Optional)"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                  sx={{ marginBottom: '20px' }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  sx={{ marginBottom: '20px' }}
                />
                <Typography variant="h6" sx={{ marginBottom: '20px', color: '#000', fontSize: { xs: '18px', md: '23px' }, fontWeight: '500' }}>City & State, or Zip</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  sx={{ marginBottom: '20px' }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  sx={{ marginBottom: '20px' }}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#01AC1C' }}
                  fullWidth
                  sx={{
                    borderRadius: '10px',
                    height: { xs: '38px', md: '48px' },
                    fontSize: { xs: '18px', md: '20px' },
                    boxShadow: 'none',
                  }}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
              </form>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, backgroundColor: '#FFFFFF', paddingBottom: { xs: '200px', md: '0' } }} />
      <BottomBar />
    </div>
  );
};

export default PersonLookup;
