import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Paper, Button, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Map } from '../graphics/Map.svg';
import BottomBar from '../components/BottomBar';
import axios from 'axios';

const PersonSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loadingDetails, setLoadingDetails] = useState(null); // Track loading state per person

  // Use useMemo to memoize searchResults
  const searchResults = useMemo(() => {
    const results = location.state?.results || [];
    console.log('Received results in PersonSearch:', results);
    return results;
  }, [location.state]);

  const [bottomPadding, setBottomPadding] = useState('0px');

  useEffect(() => {
    const cardHeight = document.querySelector('.card')?.offsetHeight || 700;
    const cardsStartOffset = 160;
    const mapHeight = window.innerWidth > 768 ? 1200 : 500;
    const padding = `${Math.max(0, cardHeight * searchResults.length - (mapHeight - cardsStartOffset))}px`;
    setBottomPadding(padding);
  }, [searchResults]);

  const handleViewDetails = async (detailsPath) => {
    const baseUrl = 'https://www.fastpeoplesearch.com/';
    const fullUrl = `${baseUrl}${detailsPath}`;

    console.log('Requesting details for full URL:', fullUrl);
    setLoadingDetails(detailsPath); // Show the loading spinner

    try {
        const response = await axios.post('https://leadhunter-backend.onrender.com/api/details', {
            url: fullUrl // Send the full URL in the body
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        console.log('Received details:', response.data);
        navigate('/person-details', { state: { personData: response.data } });
    } catch (error) {
        console.error('Error fetching person details:', error.response ? error.response.data : error.message);
    } finally {
        setLoadingDetails(null); // Hide the loading spinner
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber.slice(0, -2) + 'xx';
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bgcolor="#FFFFFF">
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" paddingX={{ xs: '10px', md: '0' }} marginBottom="160px">
        <Box paddingTop="150px">
          <Typography variant="h2" sx={{ fontSize: { xs: '10vw', md: '87px' }, fontWeight: 600, color: '#000' }}>
            Don't Hesitate to
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: '8vw', md: '64px' }, fontWeight: 600, color: '#000', paddingBottom: '20px' }}>
            reach out
          </Typography>
        </Box>
        <Box position="relative" width="100%" display="flex" justifyContent="center" alignItems="center" paddingBottom={bottomPadding}>
          <Map style={{ width: '100%', height: 'auto' }} />
          <Box position="absolute" top="160px" width="100%" display="flex" flexDirection="column" alignItems="center">
            {searchResults.length > 0 ? (
              searchResults.map((person, index) => (
                <Paper key={index} elevation={3} className="card" sx={{ maxHeight: '700px', overflow: 'hidden', width: '60%', margin: '20px auto', padding: '20px', borderRadius: '10px', boxShadow: '1px 2.5px 4px rgba(0, 0, 0, 0.25)', textAlign: 'left' }}>
                  <Typography sx={{ fontSize: '32px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>{person.name}</Typography>
                  <div style={{ height: '1px', backgroundColor: '#DFE7F7', margin: '0 4px', marginBottom: '10px' }}></div>
                  <Typography sx={{ fontSize: '24px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>Current Address</Typography>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>{person.currentAddress}</Typography>
                  <Typography sx={{ fontSize: '24px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>Previous Addresses</Typography>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>{person.pastAddresses.join(', ')}</Typography>
                  <Typography sx={{ fontSize: '24px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>Phone Numbers</Typography>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#333', marginBottom: '10px' }}>
                    {person.phoneNumbers.map(maskPhoneNumber).join(', ')}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#01AC1C',
                      color: '#fff',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#018A16',
                      },
                    }}
                    onClick={() => handleViewDetails(person.detailsURL)}  // Track loading state
                  >
                    {loadingDetails === person.detailsURL ? <CircularProgress size={24} color="inherit" /> : 'View More'}
                  </Button>
                </Paper>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary" sx={{ marginTop: '20px' }}>
                No results found.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <BottomBar />
    </Box>
  );
};

export default PersonSearch;
