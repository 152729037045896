import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogContent, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import previewImage from '../graphics/preview.png';
import about from '../graphics/about.png';
import CustomAccordion from '../components/CustomAccordion';
import FrontendInfoBar from '../components/FrontendInfoBar';
import FrontendScrollingBanner from '../components/FrontendScrollingBanner'; // Fix the import error
import HeadphonesIcon from '../icons/HeadphonesIcon.png';
import CheckmarkIcon from '../icons/CheckmarkIcon.png';
import PlantIcon from '../icons/PlantIcon.png';
import UpwardIcon from '../icons/UpwardIcon.png';
import BottomBar from '../components/BottomBar';
import CompImage from '../graphics/vsComp.png';

const paragraphs = [
  {
    title: 'How We Work',
    text: 'Our platform leverages cutting-edge technology to provide you with leads that match your precise criteria. Whether you\'re looking for properties with a specific number of bedrooms, bathrooms, or a particular home value, our tool allows you to filter and target with unmatched accuracy.'
  },
  {
    title: 'Our Mission',
    text: 'At LeadHunter, we are dedicated to revolutionizing the rental management industry by providing unparalleled lead generation services. Our goal is to help property managers and rental companies connect with the right tenants, effortlessly and efficiently.'
  },
  {
    title: 'Our Story',
    text: 'Founded by industry experts who understand the unique challenges of rental management, LeadHunter was born from a desire to simplify the lead generation process. We believe that finding the perfect tenant shouldn\'t be a hassle, and our innovative tool is designed to make that vision a reality.'
  }
];

const FrontendAbout = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const accordionItems = [
    { title: 'Customer-Centric Approach', details: 'Your success is our priority. We work closely with our clients to ensure our tool meets their needs and exceeds their expectations.' },
    { title: 'Proven Results', details: 'Our clients have seen significant improvements in their lead conversion rates, occupancy times, and overall business efficiency.' },
    { title: 'Scalable Solutions', details: 'Whether you manage a handful of properties or hundreds, our platform scales with your business, providing consistent quality and performance.' },
    { title: 'Continuous Improvement', details: 'We are committed to continuous innovation and regularly update our platform with new features and improvements based on client feedback and industry trends.' }
  ];

  const accordionIcons = [HeadphonesIcon, CheckmarkIcon, PlantIcon, UpwardIcon];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" paddingTop="50px" textAlign="center">
        <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ width: '100%', padding: '20px' }}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="center">
            <Box textAlign="left">
              <Typography variant="h2" sx={{ fontSize: { xs: '10vw', sm: '8vw', md: '6vw' }, fontWeight: 600, paddingLeft: { xs: '0', sm: '10%' }, color: '#000' }}>
                Welcome to <span style={{ color: '#01AC1C' }}>LeadHunter</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" height={{ xs: '300px', sm: '200px' }} overflow="hidden" position="relative">
              <Box
                className="scrolling-text"
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  animation: 'scroll 40s ease-in-out infinite'
                }}
              >
                {paragraphs.map((paragraph, index) => (
                  <Box key={index} sx={{ height: { xs: '300px', sm: '200px' }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="body1" sx={{ fontSize: { xs: '4vw', sm: '3vw', md: '18px' }, fontWeight: 700, color: '#000', textAlign: 'left' }}>
                      {paragraph.title}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: { xs: '4vw', sm: '3vw', md: '18px' }, fontWeight: 400, color: '#000', textAlign: 'left', marginTop: '10px' }}>
                      {paragraph.text}
                    </Typography>
                  </Box>
                ))}
                {paragraphs.map((paragraph, index) => (
                  <Box key={`clone-${index}`} sx={{ height: { xs: '300px', sm: '200px' }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="body1" sx={{ fontSize: { xs: '4vw', sm: '3vw', md: '18px' }, fontWeight: 700, color: '#000', textAlign: 'left' }}>
                      {paragraph.title}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: { xs: '4vw', sm: '3vw', md: '18px' }, fontWeight: 400, color: '#000', textAlign: 'left', marginTop: '10px' }}>
                      {paragraph.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" alignItems="center" position="relative" margin="40px 0" width="100%" maxWidth="1000px">
          <img src={previewImage} alt="Preview" style={{ borderRadius: '30px', width: '100%', height: 'auto' }} />
        </Box>
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8, color: '#000', zIndex: 2 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ padding: 0 }}>
            <Box position="relative" paddingTop="56.25%" height="0" overflow="hidden">
              <iframe
                title="YouTube Video"
                src="https://www.youtube.com/embed/your_video_id"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
        <FrontendScrollingBanner />
        <Box textAlign="center" margin="40px 0">
          <Typography variant="h6" sx={{ fontSize: '24px', fontWeight: 'medium' }}>
            This Is How We Work
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: '8vw', sm: '6vw', md: '53px' }, fontWeight: 'medium', marginTop: '20px' }}>
            Why You Will Choose Us
          </Typography>
        </Box>
        <Grid container spacing={2} style={{ width: '90%', margin: '40px 0' }}>
          <Grid item xs={12} sm={6}>
            <CustomAccordion
              items={accordionItems}
              icons={accordionIcons}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src={about} alt="About.png" style={{ width: '100%', borderRadius: '30px' }} />
          </Grid>
        </Grid>
        <FrontendInfoBar />
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" margin="40px 0">
          <img src={CompImage} alt="Additional Sample Statistics" style={{ width: '90%', borderRadius: '30px' }} />
        </Box>
        <Box width="100%" textAlign="center" marginTop="60px" paddingBottom="60px" id="signup">
          <Box sx={{ borderRadius: '30px', padding: '40px', marginTop: '40px', height: { xs: 'auto', md: '200px' }, width: { xs: 'auto', md: 'auto' }, minWidth: { xs: '300px', md: 'auto' }, marginX: 'auto' }}>
            <Typography variant="h4" sx={{ fontSize: { xs: '6vw', md: '3vw' }, color: '#000', marginBottom: '20px' }}>
              Ready to boost your business with <span style={{ color: '#01AC1C' }}>LeadHunter</span>?
            </Typography>
            <Typography variant="h5" sx={{ fontSize: { xs: '4vw', md: '2vw' }, color: '#000', marginBottom: '20px' }}>
              Sign up now and get a free trial!
            </Typography>
            <a href="#signup" style={{ textDecoration: 'none' }}>
              <button style={{ padding: '10px 20px', fontSize: '18px', backgroundColor: '#01AC1C', color: '#FFF', border: 'none', borderRadius: '5px' }}>
                Get Started
              </button>
            </a>
          </Box>
        </Box>
      </Box>
      <BottomBar />
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateY(0); }
            20% { transform: translateY(0); }
            25% { transform: translateY(-200px); }
            45% { transform: translateY(-200px); }
            50% { transform: translateY(-400px); }
            70% { transform: translateY(-400px); }
            75% { transform: translateY(-600px); }
            95% { transform: translateY(-600px); }
            100% { transform: translateY(0); }
          }

          @media (max-width: 600px) {
            @keyframes scroll {
              0% { transform: translateY(0); }
              20% { transform: translateY(0); }
              25% { transform: translateY(-300px); }
              45% { transform: translateY(-300px); }
              50% { transform: translateY(-600px); }
              70% { transform: translateY(-600px); }
              75% { transform: translateY(-900px); }
              95% { transform: translateY(-900px); }
              100% { transform: translateY(0); }
            }
          }
        `}
      </style>
    </div>
  );
};

export default FrontendAbout;
