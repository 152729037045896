import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const FrontendInfoBar = () => {
  const [stats, setStats] = useState({
    users: 0,
    leads: 0,
    emailsSent: 0,
    analysis: 0
  });

  const animateNumbers = () => {
    const targetStats = {
      users: 5000,
      leads: 3000,
      emailsSent: 10000,
      analysis: 2000
    };

    const duration = 2000;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(duration / frameDuration);
    let frame = 0;

    const animate = () => {
      frame++;
      const progress = frame / totalFrames;

      setStats({
        users: Math.floor(progress * targetStats.users),
        leads: Math.floor(progress * targetStats.leads),
        emailsSent: Math.floor(progress * targetStats.emailsSent),
        analysis: Math.floor(progress * targetStats.analysis)
      });

      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  useEffect(() => {
    animateNumbers();
  }, []);

  return (
    <Box width="90%" display="flex" justifyContent="center" alignItems="center" marginTop="60px" paddingBottom="60px">
      <Box
        sx={{
          backgroundColor: '#F2F2F2',
          borderRadius: '30px',
          padding: '20px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: '20px',
          width: '100%'
        }}
      >
        <Box textAlign="center" padding="0 20px">
          <Typography variant="h4" sx={{ fontSize: { xs: '6vw', md: '2vw' }, color: '#000' }}>{stats.users}</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '4vw', md: '1.25vw' }, color: '#000' }}>Users</Typography>
        </Box>
        <Box width={{ xs: '100%', md: '2px' }} height={{ xs: '2px', md: '100%' }} bgcolor="#F0F5FF" />
        <Box textAlign="center" padding="0 20px">
          <Typography variant="h4" sx={{ fontSize: { xs: '6vw', md: '2vw' }, color: '#000' }}>{stats.leads}</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '4vw', md: '1.25vw' }, color: '#000' }}>Leads</Typography>
        </Box>
        <Box width={{ xs: '100%', md: '2px' }} height={{ xs: '2px', md: '100%' }} bgcolor="#F0F5FF" />
        <Box textAlign="center" padding="0 20px">
          <Typography variant="h4" sx={{ fontSize: { xs: '6vw', md: '2vw' }, color: '#000' }}>{stats.emailsSent}</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '4vw', md: '1.25vw' }, color: '#000' }}>Emails Sent</Typography>
        </Box>
        <Box width={{ xs: '100%', md: '2px' }} height={{ xs: '2px', md: '100%' }} bgcolor="#F0F5FF" />
        <Box textAlign="center" padding="0 20px">
          <Typography variant="h4" sx={{ fontSize: { xs: '6vw', md: '2vw' }, color: '#000' }}>{stats.analysis}</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '4vw', md: '1.25vw' }, color: '#000' }}>Analysis</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FrontendInfoBar;
