import React from 'react';
import { Box, Typography } from '@mui/material';
import Marquee from 'react-fast-marquee';
import breakaway from '../icons/breakaway.png';
import royalshell from '../icons/royalshell.jpg';
import vacationrentals from '../icons/vacationrentalsofflorida.png';
import casamia from '../icons/casamia.jpg';

const companyInfo = [
  { name: 'Breakaway Vacation Rentals', logo: breakaway },
  { name: 'Royal Shell', logo: royalshell },
  { name: 'Vacation Rentals of Florida', logo: vacationrentals },
  { name: 'Casa Mia', logo: casamia },
];

const FrontendScrollingBanner = () => {
  return (
    <Box width="100%" padding="40px 0" marginTop="40px">
      <Marquee 
        direction="right" 
        speed={50} 
        gradient={false} 
        play={true}
        pauseOnHover={true}
        loop={0} 
        easing="ease-in-out"
      >
        {companyInfo.concat(companyInfo).map((company, index) => (
          <div key={index} className="image_wrapper" style={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
            <img src={company.logo} alt={`${company.name} Logo`} style={{ width: 'auto', height: '50px', marginRight: '10px', borderRadius: '5px' }} />
            <Typography variant="h6" sx={{ fontSize: { xs: '12px', sm: '16px' }, fontFamily: 'SF Pro Display', fontWeight: 500 }}>
              {company.name}
            </Typography>
          </div>
        ))}
      </Marquee>
    </Box>
  );
};

export default FrontendScrollingBanner;
