import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

const FrontendEmailForm = () => {
  const [fromText, setFromText] = useState('');
  const [toText, setToText] = useState('');
  const [subjectText, setSubjectText] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isInView, setIsInView] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);  // Track if animation has started

  const fromTextFull = 'nate@clientlink.com';
  const toTextFull = 'hey@overhere.com';
  const subjectTextFull = 'Must READ!! Urgent';
  const fullMessageText = `Hey {{firstname}},

This is Nate from Clientlink! I just wanted to reach out and see if things were going well. Recently, our team has developed a software that allows you to send personalized emails to the whole state of Florida with one simple script. Would love to have you onboard! Feel free to check out more info on the other pages.

Best,
Nate Laquis`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasStarted) {
            setIsInView(true);
            setHasStarted(true);  // Set the flag to true once animation starts
          }
        });
      },
      { threshold: 0.5 }  // Trigger when 50% of the target is visible
    );

    const target = document.getElementById('typingAnimation');
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [hasStarted]);

  useEffect(() => {
    if (isInView) {
      const speed = 35; // Typing speed in milliseconds

      const typeText = async (text, setText) => {
        for (let i = 0; i <= text.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, speed));
          setText(text.slice(0, i));
        }
      };

      const startTyping = async () => {
        await typeText(fromTextFull, setFromText);
        await typeText(toTextFull, setToText);
        await typeText(subjectTextFull, setSubjectText);
        await typeText(fullMessageText, setMessageText);
      };

      startTyping();
    }
  }, [isInView, fromTextFull, toTextFull, subjectTextFull, fullMessageText]);

  // Function to apply styling to specific text patterns
  const renderTextWithHighlights = (text) => {
    const parts = text.split(/(\{\{firstname\}\})/g);
    return parts.map((part, index) =>
      part === '{{firstname}}' ? <span key={index} style={{ color: '#007bff' }}>{part}</span> : part
    );
  };

  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Box 
        sx={{ 
          maxWidth: '600px', // Set a max width to prevent overflow
          width: '100%', 
          padding: { xs: '0 10px', md: '0 30px' }, 
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #DFE5F1',
            borderRadius: '8px',
            fontFamily: 'SF Pro Display, sans-serif',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid #DFE5F1',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#A5B4CB',
                fontSize: '15px',
                fontWeight: '500',
                marginRight: '8px',
                fontFamily: 'inherit',
              }}
            >
              From:
            </Typography>
            <input
              type="text"
              value={fromText}
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: '15px',
                color: '#141736',
                fontFamily: 'inherit',
              }}
              readOnly
            />
          </Box>

          <Box
            sx={{
              borderBottom: '1px solid #DFE5F1',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
            }}
          >
            <Typography
              sx={{
                color: '#A5B4CB',
                fontSize: '15px',
                fontWeight: '500',
                marginRight: '8px',
                fontFamily: 'inherit',
              }}
            >
              To:
            </Typography>
            <input
              type="text"
              value={toText}
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: '15px',
                color: '#141736',
                fontFamily: 'inherit',
              }}
              readOnly
            />
          </Box>

          <Box
            sx={{
              borderBottom: '1px solid #DFE5F1',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
            }}
          >
            <Typography
              sx={{
                color: '#A5B4CB',
                fontSize: '15px',
                fontWeight: '500',
                marginRight: '8px',
                fontFamily: 'inherit',
              }}
            >
              Subject:
            </Typography>
            <input
              type="text"
              value={subjectText}
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: '15px',
                color: '#141736',
                fontFamily: 'inherit',
              }}
              readOnly
            />
          </Box>

          <Box
            id="typingAnimation"
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: '8px 16px',
              position: 'relative',
              flex: 1,
              overflow: 'hidden',
              height: '100%',  
            }}
          >
            {!messageText && (
              <Typography
                sx={{
                  position: 'absolute',
                  top: '8px',
                  left: '16px',
                  color: '#A5B4CB',
                  fontSize: '15px',
                  fontWeight: '500',
                  fontFamily: 'inherit',
                }}
              >
                Message:
              </Typography>
            )}
            <Box
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: '15px',
                color: '#141736',
                fontFamily: 'SF Pro Display, sans-serif',
                resize: 'none',
                padding: '8px 0',
                height: '400px',  // Set fixed height for the message box
                overflowY: 'auto',  // Add vertical scroll
                textAlign: 'left',  // Align text to the left
              }}
            >
              <Typography sx={{ whiteSpace: 'pre-line', fontSize: '15px', color: '#141736', fontFamily: 'inherit' }}>
                {renderTextWithHighlights(messageText)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'left', padding: '8px 16px' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#0177FB',  // Changed button color to green
                color: '#FFFFFF',
                textTransform: 'none',
                borderRadius: '6px',
                height: '36px',
                fontSize: '15px',
                boxShadow: 'none',
                padding: '8px 10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&.Mui-disabled': {
                  backgroundColor: '#0177FB',
                  color: '#FFFFFF',
                },
              }}
              disabled
            >
              Send Email
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FrontendEmailForm;
