import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = ({ items, icons }) => {
  const [expanded, setExpanded] = useState(false);
  const [charLimit, setCharLimit] = useState(0);
  const textBoxRef = useRef(null);

  useEffect(() => {
    if (textBoxRef.current) {
      const width = textBoxRef.current.clientWidth;
      setCharLimit(Math.floor(width / 8)); // Approximate character limit based on width
    }
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      {items.map((item, index) => {
        const previewText = item.details.length > charLimit
          ? `${item.details.substring(0, charLimit)}...`
          : item.details;

        return (
          <Box key={index}>
            <Accordion
              style={{ marginBottom: '10px', boxShadow: 'none', transition: 'all 1s ease' }}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" width="100%">
                  <img 
                    src={icons[index]} 
                    alt="icon" 
                    style={{ 
                      width: '40px', 
                      height: '40px', 
                      marginRight: '10px', 
                      alignSelf: 'flex-start', 
                      objectFit: 'contain' 
                    }} 
                  />
                  <Box display="flex" flexDirection="column" justifyContent="center" flex="1" ref={textBoxRef}>
                    <Typography variant="h5" style={{ textAlign: 'left' }}>{item.title}</Typography>
                    <Typography variant="body2" style={{ color: '#666', textAlign: 'left', transition: 'all 1s ease' }}>
                      {expanded === index ? item.details : previewText}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
            </Accordion>
            {index < items.length - 1 && <Box height="2px" bgcolor="#DFE5F1" marginBottom="10px"></Box>}
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomAccordion;
