import React from 'react';
import { Box, Typography, Paper, TextField, Button } from '@mui/material';
import { ReactComponent as Map } from '../graphics/Map.svg';
import BottomBar from '../components/BottomBar';

const FrontendContact = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF' }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop="150px" textAlign="center" paddingX={{ xs: '10px', md: '0' }}>
        <Paper elevation={0} style={{ backgroundColor: 'rgba(255, 255, 255, 0)', padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h2" sx={{ fontSize: { xs: '10vw', md: '87px' }, fontWeight: 600, color: '#000' }}>
            Don't Hesitate to
          </Typography>
          <Typography variant="h3" sx={{ fontSize: { xs: '8vw', md: '64px' }, fontWeight: 600, color: '#000' }}>
            reach out
          </Typography>
        </Paper>
        <Box position="relative" width="100%" display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
          <Map style={{ width: '100%', height: 'auto' }} />
          <Box position="absolute" width={{ xs: '90%', md: '80%' }} top={{ xs: '10%', md: '15%' }}>
            <Paper style={{ padding: '40px', borderRadius: '45px', backgroundColor: '#fff', textAlign: 'left', boxShadow: 'none' }}>
              <form noValidate autoComplete="off">
                <Typography variant="h6" sx={{ marginBottom: '20px', color: '#000', fontSize: { xs: '18px', md: '23px' }, fontWeight: '500' }}>Full Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="IC Full Name"
                  InputProps={{
                    style: {
                      fontSize: { xs: '16px', md: '18px' },
                      borderRadius: { xs: '10px', md: '30px' },
                      border: '2px solid #F2F2F2',
                      padding: '10px',
                      boxShadow: 'none',
                      height: { xs: '0px', md: '48px' },
                    },
                  }}
                  sx={{
                    marginBottom: '20px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '@media (max-width:600px)': {
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #F2F2F2',
                      },
                      '& input': {
                        fontSize: '16px',
                        height: '0px',
                      },
                    },
                  }}
                />
                <Typography variant="h6" sx={{ marginBottom: '20px', color: '#000', fontSize: { xs: '18px', md: '23px' }, fontWeight: '500' }}>Email Address</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Email Address"
                  InputProps={{
                    style: {
                      fontSize: { xs: '16px', md: '18px' },
                      borderRadius: { xs: '10px', md: '30px' },
                      border: '2px solid #F2F2F2',
                      padding: '10px',
                      boxShadow: 'none',
                      height: { xs: '0px', md: '48px' },
                    },
                  }}
                  sx={{
                    marginBottom: '20px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '@media (max-width:600px)': {
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #F2F2F2',
                      },
                      '& input': {
                        fontSize: '16px',
                        height: '0px',
                      },
                    },
                  }}
                />
                <Typography variant="h6" sx={{ marginBottom: '20px', color: '#000', fontSize: { xs: '18px', md: '23px' }, fontWeight: '500' }}>Your Message</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Your Message"
                  InputProps={{
                    style: {
                      fontSize: { xs: '16px', md: '18px' },
                      borderRadius: { xs: '10px', md: '30px' },
                      border: '2px solid #F2F2F2',
                      padding: '10px',
                      boxShadow: 'none',
                      height: { xs: '0px', md: '48px' },
                    },
                  }}
                  sx={{
                    marginBottom: '20px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                    '@media (max-width:600px)': {
                      '& .MuiOutlinedInput-root': {
                        border: '1px solid #F2F2F2',
                      },
                      '& input': {
                        fontSize: '16px',
                        height: '0px',
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#01AC1C' }}
                  fullWidth
                  sx={{
                    borderRadius: '10px',
                    height: { xs: '38px', md: '48px' },
                    fontSize: { xs: '18px', md: '20px' },
                    boxShadow: 'none',
                  }}
                >
                  Log In
                </Button>
              </form>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, backgroundColor: '#FFFFFF', paddingBottom: { xs: '200px', md: '0' } }} />
      <BottomBar />
    </div>
  );
};

export default FrontendContact;
