import React from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const BottomBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        padding: '40px 20px',
        borderTop: '1px solid #e0e0e0'
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Solutions
          </Typography>
          <Typography><Link to="/email-outbound" style={{ textDecoration: 'none', color: 'inherit' }}>Email Outbound</Link></Typography>
          <Typography><Link to="/voicemail-outreach" style={{ textDecoration: 'none', color: 'inherit' }}>Voicemail Outreach</Link></Typography>
          <Typography><Link to="/mailer-outbound" style={{ textDecoration: 'none', color: 'inherit' }}>Mailer Outbound</Link></Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Resources
          </Typography>
          <Typography><Link to="/terms-of-service" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of Service</Link></Typography>
          <Typography><Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link></Typography>
          <Typography><Link to="/free-consultation" style={{ textDecoration: 'none', color: 'inherit' }}>Free Consultation</Link></Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Company
          </Typography>
          <Typography><Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit' }}>About Us</Link></Typography>
          <Typography><Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link></Typography>
          <Typography><Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>Contact</Link></Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Social Media
          </Typography>
          <Typography><Link to="https://twitter.com" style={{ textDecoration: 'none', color: 'inherit' }}>Twitter</Link></Typography>
          <Typography><Link to="https://youtube.com" style={{ textDecoration: 'none', color: 'inherit' }}>YouTube</Link></Typography>
          <Typography><Link to="https://instagram.com" style={{ textDecoration: 'none', color: 'inherit' }}>Instagram</Link></Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Newsletter
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              placeholder="Email address.."
              size="small"
              sx={{ marginRight: '10px', flex: 1 }}
            />
            <Button variant="contained" color="primary">
              Subscribe Now
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '40px'
        }}
      >
        <Typography>&copy; 2024 ClientLink LLC, All Rights Reserved</Typography>
        <Box display="flex">
          <Typography sx={{ marginRight: '20px' }}><Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</Link></Typography>
          <Typography><Link to="/terms-of-service" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of Service</Link></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomBar;
