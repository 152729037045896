import React, { useState } from 'react';
import { Container, Box, Typography, Paper, Grid, TextField, InputAdornment, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import facebookLogo from '../graphics/facebook.png';
import instagramLogo from '../graphics/instagram.png';
import linkedinLogo from '../graphics/linkedin.png';
import tiktokLogo from '../graphics/tiktok.svg';
import twitterLogo from '../graphics/twitter.png';
import youtubeLogo from '../graphics/youtube.png';
import searchpersonlogo from '../graphics/SearchPerson.png';

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const platforms = [
    { name: 'Person Search', logo: searchpersonlogo, route: '/person-lookup', clickable: false },
    { name: 'Find LinkedIn Emails', logo: linkedinLogo, route: '/platform/linkedin', clickable: true },
    { name: 'Find Facebook Emails', logo: facebookLogo, route: '/platform/facebook', clickable: true },
    { name: 'Find Instagram Emails', logo: instagramLogo, route: '/platform/instagram', clickable: true },
    { name: 'Find Twitter Emails', logo: twitterLogo, route: '/platform/twitter', clickable: true },
    { name: 'Find YouTube Emails', logo: youtubeLogo, route: '/platform/youtube', clickable: true },
    { name: 'Find TikTok Emails', logo: tiktokLogo, route: '/platform/tiktok', clickable: true },
  ];

  const primaryColor = '#01AC1C';

  const filteredPlatforms = platforms.filter((platform) =>
    platform.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#ffffff',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: '100px',
      }}
    >
      <Container>
        <Box textAlign="center">
          <Typography variant="h2" gutterBottom align="center" sx={{ fontWeight: 700, color: '#000', marginBottom: '20px' }}>
            Pick Your Data <span style={{ color: primaryColor }}>Source</span>
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search your data sources"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: '20px', width: '100%', maxWidth: '400px', borderRadius: '30px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Grid container spacing={3} justifyContent="center">
            {filteredPlatforms.map((platform) => (
              <Grid item xs={12} sm={6} md={4} key={platform.name}>
                <Tooltip title={!platform.clickable ? "This search will be released soon" : ""} placement="top">
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '20px',
                      cursor: platform.clickable ? 'pointer' : 'not-allowed',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      borderRadius: '15px',
                      backgroundColor: '#f8f8f8',
                      color: '#333',
                      boxShadow: '0 1px 1px #DFE5F1',
                      '&:hover': {
                        transform: platform.clickable ? 'scale(1.05)' : 'none',
                        boxShadow: platform.clickable ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                      },
                    }}
                    onClick={(e) => {
                      if (!platform.clickable) {
                        e.preventDefault(); // Prevent the default click behavior
                      } else {
                        navigate(platform.route);
                      }
                    }}
                  >
                    <img
                      src={platform.logo}
                      alt={platform.name}
                      style={{ height: '50px', marginRight: '20px' }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.1rem' }}>{platform.name}</Typography>
                  </Paper>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
