import React, { useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, TextField, Button, Paper, Grid, Snackbar, Alert, Slide, IconButton, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';

const platformLogos = {
  linkedin: '/images/linkedin.png',
  facebook: '/images/facebook.png',
  instagram: '/images/instagram.png',
  tiktok: '/images/tiktok.svg',
  twitter: '/images/twitter.png',
  youtube: '/images/youtube.png',
};

const API_KEY = 'your_secret_api_key';

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};

const PlatformPage = () => {
  const { platform } = useParams();
  const [keywords, setKeywords] = useState(['']);
  const [numberOfEmails, setNumberOfEmails] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [snackbarQueue, setSnackbarQueue] = useState([]);

  const handleAddKeyword = () => {
    setKeywords([...keywords, '']);
  };

  const handleRemoveKeyword = () => {
    if (keywords.length > 1) {
      setKeywords(keywords.slice(0, -1));
    }
  };

  const handleKeywordChange = (index, value) => {
    const newKeywords = [...keywords];
    newKeywords[index] = value;
    setKeywords(newKeywords);
  };

  const handleScrape = async () => {
    if (numberOfEmails > 50) {
      enqueueSnackbar('The maximum number of emails you can scrape is 50.', 'error');
      return;
    }
    setLoading(true);
    enqueueSnackbar('This may take a minute. There is a max of 50 emails per scrape that is free. Updates will be coming soon.', 'warning');
    try {
      const response = await axios.post('https://leadhunter-backend.onrender.com/api/emailscrape', {
        baseQuery: `site:${platform}.com/`,
        userQueries: keywords.join(', '),
        targetEmailCount: numberOfEmails,
      }, {
        headers: {
          'x-api-key': API_KEY
        }
      });
      setEmails(response.data.emails);
    } catch (error) {
      console.error('Error scraping emails:', error);
      enqueueSnackbar('An error occurred while scraping emails. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const csvContent = "data:text/csv;charset=utf-8," + emails.map(item => `${item.name},${item.link},${item.emails.join(',')}`).join("\n");
    const element = document.createElement("a");
    element.href = encodeURI(csvContent);
    element.download = "emails.csv";
    document.body.appendChild(element);
    element.click();
  };

  const handleReset = () => {
    setKeywords(['']);
    setNumberOfEmails(0);
    setEmails([]);
  };

  const enqueueSnackbar = (message, severity) => {
    setSnackbarQueue((prevQueue) => [
      ...prevQueue,
      { message, severity, key: new Date().getTime() },
    ]);
  };

  const handleCloseSnackbar = (key) => {
    setSnackbarQueue((prevQueue) => prevQueue.filter((snack) => snack.key !== key));
  };

  if (!platform || !platformLogos[platform.toLowerCase()]) {
    return <div>Error: Platform not specified or unsupported</div>;
  }

  const platformLogo = platformLogos[platform.toLowerCase()];

  return (
    <Container>
      <Box mt={8}>
        <Paper
          elevation={2}
          sx={{
            backgroundColor: '#ffffff',
            padding: '40px',
            borderRadius: '20px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
            textAlign: 'center',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
            <img src={platformLogo} alt={platform} style={{ height: '50px', marginRight: '20px' }} />
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: '6vw', sm: '4.5vw', md: '3vw', lg: '2.5vw' },
                fontWeight: 600,
                color: '#333333',
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              {platform.charAt(0).toUpperCase() + platform.slice(1)}
            </Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, fontSize: { xs: '5vw', md: '2vw' }, color: '#333333' }}>
                Keywords
              </Typography>
              {keywords.map((keyword, index) => (
                <TextField
                  key={index}
                  fullWidth
                  label={`Keyword ${index + 1}`}
                  value={keyword}
                  onChange={(e) => handleKeywordChange(index, e.target.value)}
                  margin="normal"
                  variant="outlined"
                  sx={{
                    fontFamily: 'Roboto, sans-serif',
                    marginBottom: '20px',
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      fontSize: '16px',
                    },
                  }}
                />
              ))}
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button
                  onClick={handleAddKeyword}
                  startIcon={<AddIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#01AC1C',
                    color: '#ffffff',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontSize: '14px',
                    '&:hover': {
                      backgroundColor: '#018A16',
                    },
                  }}
                >
                  Add Keyword
                </Button>
                <IconButton
                  onClick={handleRemoveKeyword}
                  sx={{
                    color: '#d6d2d2',
                    borderColor: '#d6d2d2',
                    borderRadius: '8px',
                    border: '1px solid',
                    padding: '5px 10px',
                    '& .MuiSvgIcon-root': {
                      color: '#01AC1C',
                    },
                    '&:hover': {
                      borderColor: '#d6d2d2',
                      backgroundColor: '#d6d2d2',
                    },
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
                <TextField
                  type="number"
                  value={numberOfEmails}
                  onChange={(e) => setNumberOfEmails(Math.min(parseInt(e.target.value) || 0, 50))}
                  margin="normal"
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 50, step: 1 } }}
                  sx={{
                    width: '100px',
                    marginBottom: '10px',
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      fontSize: '16px',
                    },
                  }}
                />
                <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                  {emails.length === 0 ? (
                    <Button
                      variant="contained"
                      onClick={handleScrape}
                      disabled={loading}
                      sx={{
                        backgroundColor: '#333333',
                        color: '#ffffff',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        minWidth: '200px',
                        '&:hover': {
                          backgroundColor: '#222222',
                        },
                      }}
                    >
                      {loading ? 'Scraping...' : 'Scrape Emails'}
                    </Button>
                  ) : (
                    <>
                      <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', mb: 2 }}>
                        {emails.map((item, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <EmailIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={`${item.name} (${item.emails.join(', ')})`}
                              secondary={
                                <a href={item.link} target="_blank" rel="noopener noreferrer">
                                  <LinkIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> {item.link}
                                </a>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        variant="contained"
                        startIcon={<FileDownloadIcon />}
                        onClick={handleDownload}
                        sx={{
                          backgroundColor: '#333333',
                          color: '#ffffff',
                          padding: '10px 20px',
                          borderRadius: '8px',
                          fontSize: '14px',
                          minWidth: '200px',
                          '&:hover': {
                            backgroundColor: '#222222',
                          },
                        }}
                      >
                        Download Emails
                      </Button>
                      <IconButton
                        onClick={handleReset}
                        sx={{
                          color: '#01AC1C',
                          borderColor: '#A5B4CB',
                          borderRadius: '8px',
                          border: '1px solid',
                          marginLeft: '8px',
                          '&:hover': {
                            backgroundColor: '#f0f0f0',
                          },
                        }}
                      >
                        <RestartAltIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Snackbar for notifications */}
      {snackbarQueue.map((snack) => (
        <Snackbar
          key={snack.key}
          open={true}
          autoHideDuration={6000}
          onClose={() => handleCloseSnackbar(snack.key)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          TransitionComponent={SlideTransition}
          sx={{ marginTop: `${snackbarQueue.indexOf(snack) * 60}px` }}
        >
          <Alert onClose={() => handleCloseSnackbar(snack.key)} severity={snack.severity} sx={{ width: '100%' }}>
            {snack.message}
          </Alert>
        </Snackbar>
      ))}
    </Container>
  );
};

export default PlatformPage;
